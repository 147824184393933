import {Initialisation} from "./initialisation.js";
import {TrackHelper} from "./track_helper.js"
import {TilesLoader} from "./tiles_loader.js";
import {TopicPromotionLoader} from "./topic_promotion_loader.js";
import initMoreCombosTracking from "./more_combos_tracking";
import initTopicPromoTracking from "./topic_promo_tracking";
import "../scss/all.scss"


window.o_global = window.o_global || {};
window.o_global.eventQBus.on("ft3bcn.combo-presentation.init", async () => {
    const comboContents = document.getElementsByClassName("combo_content");

    window.o_combo = window.o_combo || {};
    window.o_combo.trackHelper = new TrackHelper();
    window.o_combo.initMoreCombosTracking = initMoreCombosTracking;
    window.o_combo.initTopicPromoTracking = initTopicPromoTracking;
    window.o_combo.init = new Initialisation();
    window.o_combo.tilesLoader = new TilesLoader();
    window.o_combo.topicPromotionLoader = new TopicPromotionLoader()

    window.o_combo.init.initialize(comboContents);
    await window.o_combo.tilesLoader.loadTiles("comboPromoTiles");
    window.o_combo.topicPromotionLoader.loadTopicPromotion("topicPromotion");
});

window.o_global.eventQBus.emitModuleLoaded("ft3.combo.presentation")
