
export class TilesLoader {
    async loadTiles(id) {
        let elementToBeReplaced = document.getElementById(id)
        let xhr = new XMLHttpRequest()

        function ready() {
            let HTTP_OK = 200,
                READY_STATE_DONE = 4;

            if (xhr.readyState === READY_STATE_DONE) {
                if (xhr.status === HTTP_OK) {
                    let response = xhr.responseText;
                    this.hidden = response.split("data-hidden=").pop().split('"')[1];
                    if (checkBoolean(this.hidden)) {
                        removeElement(elementToBeReplaced);
                    } else {
                        elementToBeReplaced.outerHTML = xhr.responseText;
                        window.o_util.hardcore.executeInlineScripts(document.getElementById("comboPresentationTilesContainer"));
                    }
                } else {
                    removeElement(elementToBeReplaced);
                }
            }
        }

        function checkBoolean(value) {
            return value !== undefined && value.toLowerCase() === "true";
        }

        function removeElement(elementToBeReplaced) {
            if (elementToBeReplaced && elementToBeReplaced.parentNode) {
                elementToBeReplaced.parentNode.removeChild(elementToBeReplaced);
            }
        }

        function timeout() {
            elementToBeReplaced.remove();
        }

        if (elementToBeReplaced) {
            let url = elementToBeReplaced.getAttribute("data");

            xhr.open("get", url, true);
            xhr.timeout = 5000;
            xhr.ontimeout = timeout;
            xhr.onreadystatechange = ready;
            xhr.send(null);
        }
    }
}
