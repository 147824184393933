import {submitMerge, submitMove, trackOnNextPageImpression} from "@otto-ec/tracking-bct";

export class TrackHelper {
    sendTileViewTrackingEvent(viewTrackingInfo, position, isACFA = false, comboType) {

        const widgetPayload =
            this._createWidgetPayloadWithNumberOfCombos(viewTrackingInfo.tiles.length, comboType, 'loaded', position, isACFA)

        const combosPayload =
            this._generatePageImpressionCombosPayload(viewTrackingInfo, isACFA)

        const eventPayload = [widgetPayload].concat(combosPayload)
        submitMerge({}, eventPayload)
    }

    sendTileClickTrackingEvent(clickTrackingInfo, position, isACFA = false, comboType) {
        const widgetPayload =
            this._createWidgetPayloadWithNumberOfCombos(clickTrackingInfo.numberOfCombos, comboType, 'clicked', position, isACFA)

        const hasSimilarityScore = clickTrackingInfo.clickedCombo.similarityScore !== null;

        const clickedComboPayload =
            this._generateClickedComboPayload(clickTrackingInfo, hasSimilarityScore, isACFA)

        const eventPayload = {
            name: 'click',
            features: [widgetPayload].concat(clickedComboPayload)
        }
        const featureName = isACFA
            ? "WozdaCombos"
            : "SimilarCombos"
        const oldTracking = {
            "promo_ComboType": comboType,
            "wk.promo_AttributionSource": clickTrackingInfo.clickedCombo.comboSource,
            "wk.promo_AttributionFeature": this.buildAttributionFeature(featureName, comboType)
        }
        submitMove(oldTracking, eventPayload)
        trackOnNextPageImpression({promo_Click: featureName})

    }

    buildAttributionFeature(featureName, comboType) {
        const pageCluster = window.o_util.misc.getPagecluster()
        const comboTypeCase = comboType[0].toUpperCase() + comboType.substring(1).toLowerCase();
        return `${featureName}${comboTypeCase}_${pageCluster}`
    }

    trackViewForSingleCombo(combo) {
        let viewTrackingInfo
        if (combo.getPresentation() === "LAYER") {
            viewTrackingInfo = {
                "promo_WozdaLayer": this._generateLegacyTrackingInfo(combo, "view"),
                "ot_VariationIds": this._generateVariationTrackingInfo(combo, "promo_WozdaLayer"),
                "ot_PageCluster": "Artikelkombiseite",
                "promo_ComboType": combo.getComboType()
            };
        } else {
            viewTrackingInfo = {
                "ot_PageCluster": "Artikelkombiseite",
                "promo_ComboType": combo.getComboType()
            };
        }
        submitMerge(viewTrackingInfo)

        const featureSequence = this._getFeatureSequence(combo.getDom())
        const parentId = "combo-SingleCombo-" + featureSequence
        const position = this._getFeatureNumber(combo.getDom())
        const articleCount = parseInt(combo.getArticleCount())
        const comboId = combo.getId()
        const comboSource = combo.getSource()
        const comboTracking = [
            {
                id: parentId,
                name: "SingleCombo",
                position: featureSequence,
                status: "loaded",
                labels: {
                    promo_FeatureNumber: [`${position}`],
                    promo_FilledSlots: [`${articleCount}`],
                    promo_Feature: ["true"],
                    promo_Content: [comboId],
                    promo_Source: [comboSource]
                }
            }
        ]
        const articleTracking = combo.getArticles().slice(0, combo.getArticleCount()).map(article => {
            const articlePosition = article.position
            let comingFromThisArticlesADS = (article.id === combo.getArticleNumberFromADS());
            return {
                id: parentId + "-Article-" + articlePosition,
                parentId: parentId,
                name: "SingleCombo-Article",
                position: articlePosition,
                variationId: article.variationId,
                status: "loaded",
                labels: {
                    order_ProductOnWishlist: [`${article.onWishlist}`],
                    promo_ComingFromThisArticle: [`${comingFromThisArticlesADS}`]
                }
            }
        })

        const trackingInfo = comboTracking.concat(articleTracking)

        submitMerge({}, trackingInfo)
    }

    clickHandlerForArticleClickTracking(index, combo) {
        const self = this;
        const clickedArticle = combo.getArticles()[index % combo.getArticleCount()]
        return function () {
            const legacyTrackingMoveInfo = self._getLegacyTrackingClickTrackingInfoForMove(combo, clickedArticle);
            const newMoveInfo = self._getNewClickTrackingInfoForMove(combo, clickedArticle);
            if (newMoveInfo && legacyTrackingMoveInfo) {
                submitMove(legacyTrackingMoveInfo, newMoveInfo)
                trackOnNextPageImpression({'promo_Click': 'SingleCombo'});
            }
            const feature = "promo_SingleCombo";
            const nextPiInfo = self._generateClickTrackingInfoForNextPi(index + 1, feature);
            if (nextPiInfo) {
                trackOnNextPageImpression(nextPiInfo)
            }
        };
    }

    _generateLegacyTrackingInfo(combo, status) {
        return "" + combo.getId() + "∞" +
            this._getFeatureSequence(combo.getDom()) + "$" + this._getFeatureNumber(combo.getDom()) + "∞" +
            combo.getArticleCount() + "∞" +
            "n/a∞" +
            combo.getSource() + "∞" +
            status;
    }

    _getFeatureNumber(container) {
        if (container.getAttribute('data-feature_number') != null) {
            return parseInt(container.getAttribute('data-feature_number'), 0);
        } else if (document.getElementsByTagName('html')[0].getAttribute('data-pagecluster') === "Artikelkombiseite") {
            return 1;
        } else {
            return 99;
        }
    }

    _getFeatureSequence(container) {
        if (container.parentElement.getAttribute('data-feature-order') != null) {
            return parseInt(container.parentElement.getAttribute('data-feature-order'), 0);
        } else if (document.getElementsByTagName('html')[0].getAttribute('data-pagecluster') === "Artikelkombiseite") {
            return 1;
        } else {
            return 99;
        }
    }

    _generateVariationTrackingInfo(combo, feature) {
        let trackingInfo = {};
        [].map.call(combo.getArticles(), article => {
            let variationId = article.getVariationId();
            trackingInfo[variationId] = {
                "product_ListPosition": [article.getPosition().toString()],
                "product_ListFeature": [feature]
            };

            if (combo.isComingFromArticleDetailPage()) {
                trackingInfo[variationId]["promo_ComingFromThisArticle"] = article.id === combo.getArticleNumberFromADS()
            }

            trackingInfo[variationId]["order_ProductOnWishlist"] = "" + article.onWishlist;
        });
        return trackingInfo;
    }

    _getLegacyTrackingClickTrackingInfoForMove(combo, clickedArticle) {
        const productOnWhishlist = clickedArticle.onWishlist
        const source = combo.source;
        const featureName = "SingleCombo"

        let legacyTrackingInfo = {
            "promo_ComboType": combo.getComboType(),
            "wk.promo_AttributionFeature": this.buildAttributionFeature(featureName, combo.getComboType()),
            "wk.promo_AttributionSource": source,
        };

        if (combo.getTopicId()) {
            legacyTrackingInfo["wk.sd_TopicId"] = combo.getTopicId();
        }

        if (clickedArticle) {
            legacyTrackingInfo["order_ProductOnWishlist"] = productOnWhishlist;
        }

        return legacyTrackingInfo;
    }

    _getNewClickTrackingInfoForMove(combo, clickedArticle) {
        const featureSequence = this._getFeatureSequence(combo.getDom())
        const parentId = "combo-SingleCombo-" + featureSequence
        const position = this._getFeatureNumber(combo.getDom())
        const articleCount = parseInt(combo.getArticleCount())
        const comboId = combo.getId()
        const comboSource = combo.getSource()
        const articlePosition = clickedArticle.getPosition()
        const variationId = clickedArticle.getVariationId()
        const productOnWhishlist = clickedArticle.onWishlist
        const comingFromThisArticle = clickedArticle.id === combo.getArticleNumberFromADS()

        return {
            name: "click",
            features: [
                {
                    id: parentId,
                    name: "SingleCombo",
                    position: featureSequence,
                    status: "clicked",
                    labels: {
                        promo_FeatureNumber: [`${position}`],
                        promo_FilledSlots: [`${articleCount}`],
                        promo_Feature: ["true"],
                        promo_Content: [comboId],
                        promo_Source: [comboSource]
                    }
                },
                {
                    id: parentId + "-Article-" + articlePosition,
                    parentId: parentId,
                    name: "SingleCombo-Article",
                    position: articlePosition,
                    variationId: variationId,
                    status: "clicked",
                    labels: {
                        order_ProductOnWishlist: [`${productOnWhishlist}`],
                        ...combo.isComingFromArticleDetailPage() && {promo_ComingFromThisArticle: [`${comingFromThisArticle}`]}
                    }
                }
            ]
        };
    }

    _generateClickTrackingInfoForNextPi(position, feature) {
        return {
            "product_ListPosition": position,
            "product_ListFeature": feature
        };
    }

    _createWidgetPayloadWithNumberOfCombos(numberOfCombos, comboType, status, position, isACFA) {
        return {
            id: isACFA ? 'combo-WozdaCombos' : "combo-SimCombos",
            name: isACFA ? 'WozdaCombos' : "SimilarCombos",
            position: position,
            status: status,
            labels: {
                promo_FilledSlots: [`${numberOfCombos}`],
                promo_ComboType: [comboType],
                promo_FeatureNumber: ["1"],
                promo_Feature: ["true"]
            }
        };
    }

    _generateClickedComboPayload(clickTrackingInfo, hasSimilarityScore, isACFA) {
        return {
            id: (isACFA ? 'combo-WozdaCombo-' : 'combo-SimCombo-') + clickTrackingInfo.clickedCombo.position,
            parentId: isACFA ? 'combo-WozdaCombos' : 'combo-SimCombos',
            name: isACFA ? 'WozdaCombo' : 'SimilarCombo',
            position: clickTrackingInfo.clickedCombo.position,
            status: 'clicked',
            labels: {
                promo_Content: [clickTrackingInfo.clickedCombo.id],
                promo_Source: [clickTrackingInfo.clickedCombo.comboSource],
                ...!isACFA && hasSimilarityScore && {promo_ComboSimilarity: [`${clickTrackingInfo.clickedCombo.similarityScore}`]}
            }
        };
    }

    _generatePageImpressionCombosPayload(viewTrackingInfo, isACFA) {
        return viewTrackingInfo.tiles.map((comboInfo, index) => {
            const hasSimilarityScore = comboInfo.similarityScore !== null;
            return {
                id: (isACFA ? 'combo-WozdaCombo-' : 'combo-SimCombo-') + (index + 1),
                parentId: isACFA ? 'combo-WozdaCombos' : 'combo-SimCombos',
                name: isACFA ? 'WozdaCombo' : 'SimilarCombo',
                position: index + 1,
                status: 'loaded',
                labels: {
                    promo_Content: [comboInfo.id],
                    ...!isACFA && hasSimilarityScore && {promo_ComboSimilarity: [`${comboInfo.similarityScore}`]},
                    promo_Source: [comboInfo.comboSource]
                }
            }
        });
    }
}
